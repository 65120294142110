import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { getClient } from './client';

export const ApolloWrapper = ({ element, apiUrl }) => (
  <ApolloProvider client={getClient(apiUrl)}>
    <div id="root">
      {element}
    </div>
  </ApolloProvider>
);
