const colors = {
  black: '#0B0D17',
  white: '#ffffff',
  lightGray: '#e4e4e4',
  gray: '#b2b2b2',
  darkGray: '#444444',
  quoteText: '#343D48',
  menu: '#ffffff',
  tycoonMain: '#097C97',
  facebook: '#3b5998',
  darkFacebook: '#1e2e4f',
  twitter: '#1da1f2',
  darkTwitter: '#0967a0',
  linkedin: '#006192',
  darkLinkedin: '#001d2c',
  telegram: '#08c',
  darkTelegram: '#004466',
  email: '#757575',
  darkEmail: '#424242',
  red: '#ff0000'
};

export default colors;