import colors from './colors';

const theme = {
  fontSizes: {
    xs: '.6rem',
    s: '.8rem',
    m: '1rem',
    l: '1.2rem',
    xl: '1.6rem',
    xxl: '2rem',
    h1: {
      xs: '1.4rem',
      s: '1.7rem',
      m: '2rem',
      l: '2.3rem',
      xl: '2.5rem',
      xxl: '3rem'
    },
    h2: {
      xs: '1.2rem',
      s: '1.5rem',
      m: '1.8rem',
      l: '2.1rem',
      xl: '2.3rem'
    },
    h3: {
      xs: '1rem',
      s: '1.3rem',
      m: '1.6rem',
      l: '1.9rem',
      xl: '2.1rem'
    }
  },
  fontWeights: [300, 400, 500, 600, 700, 800, 900],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
    big: 2
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
  },
  fonts: {
    arial: '"Arial", sans-serif'
  },
  colors,
  zIndexes: {
    aboveAll: 999,
    basic: 1
  },
  breakpoints: [
    '1100px',
    '860px',
    '700px',
    '585px',
    '440px',
    '360px'
  ],
  mapFooterHeight: {
    main: '470px',
    '860px': '650px',
    '585px': '750px',
    '440px': '1050px',
    '360px': ''
  }
};

export default theme;
