import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { ApolloWrapper } from './src/apollo/wrapper';
import { store } from './src/redux';
import theme from './src/common/theme';

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ApolloWrapper element={element} apiUrl={process.env.GATSBY_API_URL} />
      </ThemeProvider>
    </Provider>
  )
};
