export const actionTypes = {
  SET_SCROLL_POSITION: 'SET_SCROLL_POSITION',
  SET_BLOG_PAGINATION_SETTINGS: 'SET_BLOG_PAGINATION_SETTINGS',
  SAVE_WINDOW_WIDTH: 'SAVE_WINDOW_WIDTH'
};

export const setScrollPosition = scrollPosition => ({
  type: actionTypes.SET_SCROLL_POSITION,
  scrollPosition
});

export const setBlogPaginationSettings = blogPaginationSettings => ({
  type: actionTypes.SET_BLOG_PAGINATION_SETTINGS,
  blogPaginationSettings
});
