import { actionTypes } from './actions';

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_SCROLL_POSITION:
      return { ...state, scrollPosition: action.scrollPosition };
    
    case actionTypes.SET_BLOG_PAGINATION_SETTINGS:
      return { ...state, blogPaginationSettings: { ...action.blogPaginationSettings } };

    default:
      return state;
  }
}